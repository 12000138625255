<template>
  <v-container>
    <template v-if="$route.path.includes('processing')">
      <h3 v-html="$t('subsidycreditor_processing_begruessungstitel')"></h3>
      <p v-if="disableSubsidyCreditor" v-html="$t('subsidycreditor_processing_maintenance_message')"></p>
      <p v-html="$t('subsidycreditor_processing_begruessungtext')"></p>
      <div v-for="dairy in dairies" v-bind:key="dairy.id">
        <template v-if="hasCreditorByLocalUnitId(dairy.id)">
          <v-card>
            <v-card-title>
              {{ dairy.formattedName }} &nbsp;
              <span v-html="$t('subsidycreditor_processing_mbh_ident_title')" />
              &nbsp; {{ dairy.ident }}
            </v-card-title>
            <v-card-text>
              <span v-html="$t('subsidycreditor_uebersichtadressetitel')" />
              <p>
                {{ findCreditorByLocalUnitId(dairy.id).name }}
                <br />
                {{ findCreditorByLocalUnitId(dairy.id).addressAddition }}

                <br />
                {{ findCreditorByLocalUnitId(dairy.id).strasse }}
                {{ findCreditorByLocalUnitId(dairy.id).hausnummer }}
                <br />
                {{ findCreditorByLocalUnitId(dairy.id).plz }}
                {{ findCreditorByLocalUnitId(dairy.id).ort }}
                <br />
                <br />
                <span v-if="findCreditorByLocalUnitId(dairy.id).bank && findCreditorByLocalUnitId(dairy.id).bank.name">
                  {{ findCreditorByLocalUnitId(dairy.id).bank.name }}</span
                >
                <br />
                {{ iban4Blocks(findCreditorByLocalUnitId(dairy.id).iban) }}
                <br />
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn :disabled="disableSubsidyCreditor" @click="showDialogBearbeiten(dairy.id)" color="primary"
                ><span v-html="$t('subsidycreditor_bearbeitenbtn')"
              /></v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-else>
          <v-card>
            <v-card-title>
              {{ dairy.formattedName }} &nbsp;
              <span v-html="$t('subsidycreditor_processing_mbh_ident_title')" />
              &nbsp; {{ dairy.ident }}
            </v-card-title>
            <v-card-text>
              <span v-html="$t('subsidycreditor_processing_createtext')" />
            </v-card-text>
            <v-card-actions>
              <v-btn :disabled="disableSubsidyCreditor" @click="showDialogBearbeiten(dairy.id, 'newCreditor')" color="primary"
                ><span v-html="$t('subsidycreditor_processing_createbtn')"
              /></v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </div>
    </template>

    <template v-if="$route.path.includes('production')">
      <div v-if="!isSAPKreditor">
        <h3 v-html="$t('subsidycreditor_begruessungstitel')"></h3>
        <p v-if="disableSubsidyCreditor" v-html="$t('subsidycreditor_production_maintenance_message')"></p>
        <p v-html="$t('subsidycreditor_begruessungtext')"></p>
        <v-btn color="primary" :disabled="disableSubsidyCreditor" @click="dialog = true"><span v-html="$t('subsidycreditor_begruessungbutton')" /></v-btn>
      </div>

      <div v-if="isSAPKreditor">
        <template v-if="creditorType == 'production'">
          <h3 v-html="$t('subsidycreditor_uebersichttitel')"></h3>
          <p v-if="disableSubsidyCreditor" v-html="$t('subsidycreditor_production_maintenance_message')"></p>
          <p v-html="$t('subsidycreditor_uebersichttext')"></p>
        </template>
        <v-card>
          <v-card-title>
            <span v-html="$t('subsidycreditor_uebersichtadressetitel')" />
          </v-card-title>
          <v-card-text>
            <p>
              {{ kreditor.name }}
              <br />
              {{ kreditor.addressAddition }}
              <br v-if="kreditor.addressAddition && kreditor.addressAddition.length > 0" />
              {{ kreditor.strasse }} {{ kreditor.hausnummer }}
              <br />
              {{ kreditor.plz }} {{ kreditor.ort }}
              <br />
              <br />
              <span v-if="kreditor.bank && kreditor.bank.name">
                {{ kreditor.bank.name }}
              </span>
              <br />
              {{ iban4Blocks(kreditor.iban) }}
              <br />
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn :disabled="disableSubsidyCreditor" @click="showDialogBearbeiten" color="primary"
              ><span v-html="$t('subsidycreditor_bearbeitenbtn')"
            /></v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </template>

    <v-dialog persistent scrollable v-model="dialog" max-width="1200">
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <div v-if="tabIndex === 0">
            <v-form ref="subsidyinvoiceformtab0" name="subsidyinvoiceformtab0" v-model="valid" lazy-validation @submit.prevent="addressOk()">
              <p>
                <!-- this is a form text block (formerly known as help block) -->
                <span v-html="$t('subsidycreditor_ibanhilfetext')" />
              </p>
              <label for="inputLive">IBAN:</label>
              <v-text-field
                name="iban"
                id="iban"
                v-model.trim="tmpZulagen.ibanEingabe"
                type="text"
                :rules="[ibanRule]"
                placeholder="CHXX XXXX XXXX XXXX XXXX X"
              />

              <br />
              <label for="bcn">BCN:</label>
              <v-text-field name="bcn" id="bcn" v-model.trim="bcn" type="text" :disabled="true" />
              <br />

              <label for="bankName" v-html="$t('subsidycreditor_bank')"></label>
              <v-text-field name="bankname" id="bankName" v-model.trim="bank.name" type="text" :disabled="true" />
            </v-form>
          </div>
          <div v-if="tabIndex === 1">
            <p v-html="$t('subsidycreditor_zahlungsadrhello')"></p>
            <v-form ref="subsidyinvoiceformtab1" name="subsidyinvoiceformtab1" v-model="valid" lazy-validation @submit.prevent="addressOk()">
              <div class="row">
                <div class="col-12 col-lg-6">
                  <p>
                    <b v-html="$t('subsidycreditor_adressetitelstamm')"></b>
                  </p>
                  <p>
                    {{ defaultAddress.name1 }} {{ defaultAddress.name2 }}
                    <br />

                    {{ defaultAddress.addressAddition }}
                    <br v-if="defaultAddress.addressAddition && defaultAddress.addressAddition.length > 0" />
                    {{ defaultAddress.attention }}
                    <br v-if="defaultAddress.attention && defaultAddress.attention.length > 0" />
                    {{ defaultAddress.strasse }}
                    <br />
                    {{ defaultAddress.zip }} {{ defaultAddress.locality }}
                    <br />
                  </p>
                  <p>
                    <v-btn @click="adresseKopieren" color="primary"><span v-html="$t('subsidycreditor_adressekopierenbtn')" /></v-btn>
                  </p>
                </div>
                <div class="col-12 col-lg-6">
                  <p>
                    <b v-html="$t('subsidycreditor_adressetitelzahlung')"></b>
                  </p>
                  <div class="row mb-3">
                    <div class="col-12 col-md-12">
                      <div role="group">
                        <v-text-field
                          name="namex"
                          id="name"
                          v-model.trim="tmpZulagen.name"
                          type="text"
                          :rules="[nameRule]"
                          :hint="$t('subsidycreditor_adressenametooltip')"
                          :label="$t('subsidycreditor_name') + ' ' + $t('subsidycreditor_vorname')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <v-text-field
                      name="addressAddition"
                      id="addressAddition"
                      v-model.trim="tmpZulagen.addressAddition"
                      type="text"
                      :rules="[addressAdditionRule]"
                      :label="$t('subsidycreditor_zusatz')"
                    />
                  </div>
                  <div class="row mb-3">
                    <div class="col-8">
                      <div role="group">
                        <v-text-field
                          id="strasse"
                          v-model.trim="tmpZulagen.strasse"
                          type="text"
                          :rules="[strasseRule]"
                          :placeholder="$t('subsidycreditor_adressestrassetooltip')"
                          :label="$t('subsidycreditor_strasse')"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div role="group">
                        <v-text-field
                          name="hausnummer"
                          id="hausnummer"
                          v-model.trim="tmpZulagen.hausnummer"
                          type="text"
                          :rules="[hausnummerRule]"
                          :label="$t('subsidycreditor_adressehausnr')"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-2">
                      <v-text-field
                        name="zip"
                        id="zip"
                        v-model.trim="tmpZulagen.zip"
                        type="text"
                        :rules="[zipRule]"
                        :label="$t('subsidycreditor_adresseplz')"
                        @change="loadLocations(tmpZulagen.zip)"
                      />
                    </div>
                    <div class="col-6" v-if="tmpZulagen.locality && !locations">
                      <v-text-field
                        disabled
                        name="locality"
                        v-model="tmpZulagen.locality"
                        item-text="localityLong"
                        item-value="localityLong"
                        :label="$t('subsidycreditor_ort')"
                        :rules="[localityRule]"
                      ></v-text-field>
                    </div>
                    <div class="col-6" v-else>
                      <v-select
                        name="locality"
                        id="locality"
                        v-model="tmpZulagen.locality"
                        :items="locations"
                        item-value="ortsbez27"
                        item-title="ortbez27"
                        :label="$t('subsidycreditor_ort')"
                        :rules="[localityRule]"
                      >
                      </v-select>
                    </div>
                    <div class="col-4">
                      <v-select
                        id="countryRegions"
                        :disabled="tmpZulagen.locality && !locations"
                        v-model="tmpZulagen.countryRegion"
                        :label="$t('canton')"
                        :items="countryRegions"
                        :item-title="$getLangKey()"
                        item-value="name"
                        :rules="[countryRegionRule]"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <v-checkbox
                    name="adresseagb"
                    id="AdresseAGB"
                    required
                    v-model="tmpZulagen.agbChecked"
                    :rules="[agbRule]"
                    :label="$t('subsidycreditor_zahlungsadragb')"
                  ></v-checkbox>
                </div>
                <div
                  class="col-12"
                  v-if="
                    $privileges.hasOneOf([
                      { path: '/subsidyCreditors/production', permission: 'patchNoSAP' },
                      { path: '/subsidyCreditors/processing', permission: 'patchNoSAP' }
                    ])
                  "
                >
                  <v-checkbox name="patchNoSAP" id="patchNoSAP" v-model="patchNoSAP" :label="$t('subsidycreditor_patchNoSAP')"></v-checkbox>
                </div>
              </div>
            </v-form>
          </div>
          <div v-if="tabIndex === 2">
            <p v-html="$t('subsidycreditor_zahlungsabschlusstxt')"></p>

            <div class="row">
              <div class="col-12 col-lg-6">
                <p>
                  <b v-html="$t('subsidycreditor_iban')"></b>
                  <br />
                  {{ iban4Blocks(tmpZulagen.iban) }}
                </p>
              </div>

              <div class="col-12 col-lg-6">
                <b v-html="$t('subsidycreditor_adresse')"></b>
                <p>
                  {{ tmpZulagen.name }}
                  <!--{{ tmpZulagen.vorname }}-->
                  <br />
                  {{ tmpZulagen.addressAddition }}
                  <br />
                  {{ tmpZulagen.strasse }}&nbsp;{{ tmpZulagen.hausnummer }}
                  <br />
                  {{ tmpZulagen.zip }}&nbsp;{{ tmpZulagen.locality }}
                  <br />
                </p>
              </div>
            </div>
            <p v-if="domain?.email">
              {{ $t('subsidycreditor_zahlungsabschlussunten').replace('XXXX', domain.email) }}
            </p>
            <p v-else>
              {{ $t('subsidycreditor_zahlungsabschlussunten').replace('XXXX', 'info@dbmilch.ch') }}
            </p>
          </div>
          <div v-if="tabIndex === 3">
            <p v-if="sendStatus == 0">warte auf versand ..</p>
            <p v-if="sendStatus == 1" v-html="$t('subsidycreditor_sapsendinprogress')"></p>

            <v-alert v-if="sendStatus == 2" show color="success"
              ><span v-html="this.patchNoSAP === true ? $t('subsidycreditor_send_no_sap_ok') : $t('subsidycreditor_sapsendok')"
            /></v-alert>
            <div v-if="sendStatus == 3">
              <span v-html="$t('subsidycreditor_sapsenderror')" />
              <template v-for="(value, key) in sapMeldungen" :key="key">
                <v-alert type="error">{{ value }}</v-alert>
                <v-alert v-if="value?.includes('in Land CH nicht vorhanden')" type="info" v-bind:key="key + '_info'">
                  <span v-html="subsidycreditor_ort_mehrdeutig"></span
                ></v-alert>
              </template>
            </div>
          </div>
          <br />
          <div v-if="weiterTooltip.length">{{ weiterTooltip }}</div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="tabsZurueck" class="mr-2" v-if="zurueckBtnShow" :disabled="zurueckBtnDisabled">
            <span v-html="this.$t('subsidycreditor_back')"></span>
          </v-btn>
          <v-btn @click="dialog = false" class="mr-2" v-if="abbrechenBtnShow"><span v-html="this.$t('subsidycreditor_abbrechen')"></span></v-btn>

          <v-btn id="weiterBtn" class="mr-2" v-if="weiterBtnShow" color="primary" @click="tabsWeiter"><span v-html="$t('subsidycreditor_weiter')" /></v-btn>
          <v-btn @click="dialog = false" class="mr-2" v-if="schliessenBtnShow" color="primary"
            ><span v-html="$t('subsidycreditor_dialogschliessenbtn')"
          /></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { apiURL, emitter } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { useLegalEntityStore } from '@/store/LegalEntityStore'
import _ from 'lodash'

// https://www.npmjs.com/package/iban
import IBAN from 'iban'
import { stringOrEmpty } from '@/utils/various'
const legalEntityStore = useLegalEntityStore()
import { useCountryRegionsStore } from '@/store/enums/CountryRegionsStore'

const kreditorDefault = {
  id: null,
  countryRegion: null, // CH_BE
  kreditor: '',
  iban: '',
  ibanEingabe: process.env.NODE_ENV === 'development' ? 'CH8800767000L06546550' : '',
  oldIban: '',
  ibanError: '',
  agbChecked: false,
  name: '', //name + vornmae
  addressAddition: '', //addressAddition
  strasse: '',
  hausnummer: '',
  zip: '',
  locality: '',
  emailVersand: 1,
  localUnitId: null, // only set when creditor is a dairy, not set 4 farms
  bank: { name: '', bcnr: '' }, // remote BANK INFO{}
  locale: 'de',
  email: '',
  telefon: ''
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'zulagen',
  props: ['creditorType'], // via Router
  data() {
    return {
      patchNoSAP: false,
      dialog: false,
      disableSubsidyCreditor: false, // because of SAP maintenance
      subsidyForm: undefined,
      valid: true,
      defaultAddress: null,
      dairies: [],
      dairySelected: null,
      tabIndex: 0,
      zurueckBtn: { show: true },
      abbrechenBtn: { show: true },
      sendStatus: 0, // 0 idle, 1 sending, 2 done, 3 errror
      sapMeldungen: [],
      bcn: '', //lokale BCN info
      bank: { name: '', bcnr: '' }, // remote BANK INFO
      // im Dialog sind die Daten nicht persistent.
      // ist die liste aller gespeicherten Kreditoren, die Daten
      kreditors: [],
      // ist der aktuell gewählte kreditor
      kreditor: { ...kreditorDefault },
      // ist der arbeitsbereich, hier werden die felder zwischengespeichert
      tmpZulagen: { ...kreditorDefault },
      fielddef: [
        { name: 'iban', min: 21, max: 21 },
        { name: 'name', min: 2, max: 40 },
        { name: 'attention', min: 0, max: 40 },
        { name: 'strasse', min: 1, max: 60 },
        { name: 'hausnummer', min: 0, max: 10 },
        { name: 'zip', min: 4, max: 4 },
        { name: 'locality', min: 2, max: 40 }
      ],
      locations: null
    }
  },
  computed: {
    countryRegions() {
      return useCountryRegionsStore().CountryRegions
    },
    adresse() {
      return this.$store.state.userStore.adresse
    },
    domain() {
      const legalEntityStore = useLegalEntityStore()
      return legalEntityStore.getSelectedPerson
    },
    domainId() {
      const legalEntityStore = useLegalEntityStore()
      return legalEntityStore.getSelectedPersonId
    },
    isSAPKreditor() {
      if (typeof this.kreditor == 'undefined') return false
      if (typeof this.kreditor.kreditor == 'undefined') return false
      if (typeof this.kreditor.kreditor.length == 'undefined') return false
      if (this.kreditor.kreditor == '') return false
      if (this.kreditor.kreditor.length > 0) return true
      return true
    },
    ibanRule() {
      return this.tmpZulagen?.ibanError?.length == 0 ? true : this.tmpZulagen.ibanError
    },
    dialogTitle() {
      if (this.tabIndex === 0) {
        return this.$t('subsidycreditor_popupzvtitle')
      }
      if (this.tabIndex === 1) {
        return this.$t('subsidycreditor_popupzvtitleadress')
      }
      if (this.tabIndex === 2) {
        return this.$t('subsidycreditor_popupzvtitleabschluss')
      }
      if (this.tabIndex === 3) {
        return '' // gitbs nicht ?? this.$t('subsidycreditor_datenübermittlung')
      }
      return this.$t('subsidycreditor_popupzvtitle')
    },
    countryRegionRule() {
      return typeof this.tmpZulagen.countryRegion == 'string' ? true : false
    },
    //i'll validate the name
    nameRule(value) {
      return this.tmpZulagen.name.length >= this.min('name') && this.tmpZulagen.name.length <= this.max('name')
        ? true
        : this.$t('subsidycreditor_adressenametooltip')
    },

    addressAdditionRule(val) {
      if (this.tmpZulagen.addressAddition) return this.tmpZulagen.addressAddition.length <= this.max('attention') ? true : false
      else return true
    },
    //i'll validate the strasse
    strasseRule() {
      return this.tmpZulagen.strasse && this.tmpZulagen.strasse.length >= this.min('strasse') && this.tmpZulagen.strasse.length <= this.max('strasse')
        ? true
        : false
    },
    hausnummerRule() {
      if (this.tmpZulagen.hausnummer)
        return this.tmpZulagen.hausnummer.length >= this.min('hausnummer') && this.tmpZulagen.hausnummer.length <= this.max('hausnummer') ? true : false
      else return true
    },
    zipRule() {
      // if (!typeof parseInt(this.tmpZulagen.zip) == 'number') return false
      return typeof parseInt(this.tmpZulagen.zip) == 'number' && this.tmpZulagen.zip > 999 && this.tmpZulagen.zip < 9999
    },
    localityRule() {
      if (
        this.tmpZulagen.locality &&
        this.tmpZulagen.locality.length >= this.min('locality') &&
        this.tmpZulagen.locality.length <= this.max('locality') &&
        !(this.tmpZulagen.locality === '')
      ) {
        return true
      }
      return false
    },
    agbRule(val) {
      return this.tmpZulagen.agbChecked === true
    },
    weiterBtnEnabled() {
      if (this.tabIndex === 0) {
        return this.ibanRule == true ? true : false // null = false
      }
      if (this.tabIndex === 1) {
        return this.nameRule && this.strasseRule && this.zipRule && this.localityRule && this.agbRule && this.countryRegionRule
      }
      return true
    },
    weiterTooltip() {
      if (this.tabIndex === 0) return this.$t('subsidycreditor_weiterbtntooltipiban')
      if (this.tabIndex === 1) return this.$t('subsidycreditor_weiterbtntooltipadresse')
      return ''
    },
    weiterBtnShow() {
      // immer zeigen ausser bei tab3 und sendestatus != gesendet
      return this.tabIndex < 3 ? true : false
    },
    zurueckBtnDisabled() {
      if (this.tabIndex === 3 && [0, 1].includes(this.sendStatus)) {
        return true
      }
      return false
    },
    zurueckBtnShow() {
      if (this.tabIndex === 0) {
        return false
      }
      if (this.tabIndex === 3 && [2].includes(this.sendStatus)) {
        return false
      } //sendeStatus 0 idle, 1 sending, 2 done, 3 errror
      return true
    },
    abbrechenBtnShow() {
      if (this.tabIndex === 3 && [0, 1, 2].includes(this.sendStatus)) {
        return false
      } //sendeStatus 0 idle, 1 sending, 2 done, 3 errror
      return true
    },
    schliessenBtnShow() {
      if (this.tabIndex === 3 && this.sendStatus == 2) {
        return true
      } //sendeStatus 0 idle, 1 sending, 2 done, 3 errror
      return false
    },
    legalEntityId() {
      return legalEntityStore.getSelectedPersonId
    }
  },
  methods: {
    async loadDisableSubsidyCreditorFromSubsidyConfig() {
      try {
        const response = await this.axios.get(apiURL + '/subsidyConfigs/disableSubsidyCreditor/1')
        const data = response.data
        this.disableSubsidyCreditor = data
        return true
      } catch (e) {
        showError(e)
      }
    },
    hasCreditorByLocalUnitId(localUnitId) {
      return this.kreditors.filter((k) => k.localUnitId === localUnitId).length > 0
    },
    findCreditorByLocalUnitId(localUnitId) {
      const test = this.kreditors.filter((k) => k.localUnitId === localUnitId)
      if (test.length) {
        return test[0]
      } else {
        // defaultkreditor
        return {
          id: null,
          kreditor: '',
          iban: '',
          oldIban: '',
          name: '', //name + vornmae
          addressAddition: '', //zusatz
          strasse: '',
          hausnummer: '',
          zip: '',
          locality: '',
          locale: 'de',
          emailVersand: 1,
          localUnitId: localUnitId, // only set when creditor is a dairy, not set 4 farms
          bank: { name: '', bcnr: '' }, // remote BANK INFO{}
          countryRegion: null
        }
      }
    },
    min(fieldName) {
      return this.fielddef.filter((defs) => defs.name == fieldName)[0].min
    },
    max(fieldName) {
      return this.fielddef.filter((defs) => defs.name == fieldName)[0].max
    },
    iban4Blocks(val) {
      return val
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim()
    },
    async setBankByIBAN(iban) {
      if (typeof iban != 'string') throw 'iban ist undenied?'
      if (iban.length < 7) throw 'iban zu kurz'

      try {
        var bank = await app.axios.get(apiURL + '/bankmasters/find', {
          params: { iban: iban }
        })
        this.bank = bank.data
      } catch (e) {
        emitter.emit('toastError', 'keine Bankdaten gefunden!')
      }
    },
    async setKreditor(kreditor) {
      this.sendStatus = 1
      this.sendMessage = ''
      this.kreditor = kreditor
      this.kreditor.plz = this.kreditor.zip
      this.kreditor.ort = this.kreditor.locality
      this.kreditor.legalEntityId = this.domainId
      this.kreditor.email = this.defaultAddress.email
      this.kreditor.telefon =
        typeof this.defaultAddress.phone1 == 'string'
          ? this.defaultAddress.phone1
          : typeof this.defaultAddress.phone2 == 'string'
            ? this.defaultAddress.phone2
            : '000 00 00 00 00'
      let kreditorTypeURL = 'default'

      if (this.creditorType == 'processing') {
        kreditorTypeURL = '/processing'
        this.kreditor.localUnitId = this.dairySelected
      } else {
        kreditorTypeURL = '/production'
        delete this.kreditor.localUnitId
      }
      let readyForSendKreditor = _.omit(this.kreditor, 'bank', 'ibanEingabe', 'ibanError', 'vorname', 'zip', 'locality', 'agbChecked', 'emailVersand')
      this.sapMeldungen = []
      if (this.patchNoSAP === true) {
        try {
          let calculatedURL = readyForSendKreditor.id
            ? `${apiURL}/subsidyCreditors/${readyForSendKreditor.id}${kreditorTypeURL}/patchNoSAP`
            : `${apiURL}/subsidyCreditors${kreditorTypeURL}/patchNoSAP`
          const test = await axios[readyForSendKreditor.id ? 'put' : 'post'](calculatedURL, readyForSendKreditor)
          this.sendStatus = 2
          await this.loadSubsidyCreditor()
        } catch (e) {
          this.sapMeldungen.push(showError(e, 'inline'))
          this.sendStatus = 3
        }
      } else {
        try {
          let calculatedURL = readyForSendKreditor.id
            ? `${apiURL}/subsidyCreditors/${readyForSendKreditor.id}${kreditorTypeURL}`
            : `${apiURL}/subsidyCreditors${kreditorTypeURL}`
          const test = await app.axios[readyForSendKreditor.id ? 'put' : 'post'](calculatedURL, readyForSendKreditor)

          //error kann auf beiden nodes erscheinen
          if (test.data.hasError) {
            //
            for (let itemX of test.data.errorMessages) {
              this.sapMeldungen.push(itemX)
            }
            this.sendStatus = 3
          } else {
            this.sendStatus = 2
            await this.loadSubsidyCreditor()
          }
        } catch (e) {
          //emitter.emit('toastError',{ error: this.$t('toast_generic_error_message') + ' :: ' + JSON.stringify(e) })
          this.sapMeldungen.push(showError(e, 'inline'))
          this.sendStatus = 3
        }
      }
    },
    adresseKopieren() {
      //Kopiert die StammdatenAdresse
      this.tmpZulagen.name = stringOrEmpty(this.defaultAddress.name1) + ' ' + stringOrEmpty(this.defaultAddress.name2)
      this.tmpZulagen.name.trim()
      //this.tmpZulagen.vorname = this.defaultAddress.name2

      // zuhanden == attention
      // zusatz == addressAddition
      //const addressAddition = typeof this.defaultAddress.addressAddition == 'string' ? this.defaultAddress.addressAddition : ''

      // zuhanden = subsidyCreditor.addressAddition = legalEntityAddress.attention
      this.tmpZulagen.addressAddition = stringOrEmpty(this.defaultAddress.attention)
      this.tmpZulagen.strasse = stringOrEmpty(this.defaultAddress.address1)
      this.tmpZulagen.zip = stringOrEmpty(this.defaultAddress.zip)
      this.tmpZulagen.locality = stringOrEmpty(this.defaultAddress.locality)
      this.tmpZulagen.countryRegion = stringOrEmpty('CH_' + this.defaultAddress.canton)
    },
    selectCreditorByLocalUnitId(localUnitId) {
      if (this.hasCreditorByLocalUnitId(localUnitId)) {
        this.kreditor = this.findCreditorByLocalUnitId(localUnitId)
        this.dairySelected = localUnitId
        return this.kreditor
      }
      //dont select a creditor
      this.dairySelected = localUnitId
      return false
    },
    showDialogBearbeiten(localUnitId, newCreditor) {
      if (localUnitId) this.selectCreditorByLocalUnitId(localUnitId)

      this.tmpZulagen = { ...kreditorDefault }

      if (newCreditor !== 'newCreditor') {
        // Feld für Feld sonst wird die Referenz kopiert und vueJs hat Mühe mit den computed Props
        this.tmpZulagen.name = stringOrEmpty(this.kreditor?.name)
        this.tmpZulagen.addressAddition = stringOrEmpty(this.kreditor?.zusatz)
        this.tmpZulagen.zusatz = stringOrEmpty(this.kreditor?.addressAddition)
        this.tmpZulagen.strasse = stringOrEmpty(this.kreditor?.strasse)
        this.tmpZulagen.hausnummer = stringOrEmpty(this.kreditor?.hausnummer)
        this.tmpZulagen.zip = stringOrEmpty(this.kreditor?.plz)
        this.tmpZulagen.plz = stringOrEmpty(this.kreditor?.plz)
        this.tmpZulagen.locality = stringOrEmpty(this.kreditor?.ort)
        this.tmpZulagen.ort = stringOrEmpty(this.kreditor?.ort)
        this.tmpZulagen.iban = stringOrEmpty(this.kreditor?.iban)
        this.tmpZulagen.ibanEingabe = !this.kreditor ? '' : this.iban4Blocks(this.kreditor.iban)
        this.tmpZulagen.oldIban = stringOrEmpty(this.kreditor?.oldIban)
        this.tmpZulagen.countryRegion = stringOrEmpty(this.kreditor?.countryRegion)
        this.tmpZulagen.id = !this.kreditor ? null : this.kreditor.id
        this.tmpZulagen.kreditor = stringOrEmpty(this.kreditor?.kreditor)
        this.tmpZulagen.legalEntityId = this.domainId
      }
      this.dialog = true
      this.tabIndex = 0
      this.zurueckBtn.show = false
    },
    tabsZurueck() {
      this.tabIndex--
      if (this.tabIndex == 0) {
        this.zurueckBtn.show = false
      }
    },
    async tabsWeiter() {
      if (this.tabIndex == 0) {
        if (!(await this.$refs.subsidyinvoiceformtab0.validate()).valid) return false
      }
      if (this.tabIndex == 1) {
        if (!(await this.$refs.subsidyinvoiceformtab1.validate()).valid) return false
      }

      if (this.weiterBtnEnabled) {
        this.tabIndex++
        this.zurueckBtn.show = true
        if (this.tabIndex == 3) {
          this.setKreditor(this.tmpZulagen)
        }
      } else {
        if (this.tabIndex === 0) {
          this.$refs.subsidyinvoiceformtab0.validate()
        } else if (this.tabIndex === 1) {
          this.$refs.subsidyinvoiceformtab1.validate()
        } else {
          // do nothing
        }
      }
    },
    loadDairies: async function () {
      const dairies = await app.axios.get(apiURL + '/legalEntities/' + this.legalEntityId + '/dairies')
      if (await dairies.data.length) {
        this.dairies = dairies.data
      }
      return true
    },
    loadSubsidyCreditor: async function () {
      let kreditors = await app.axios.get(apiURL + '/legalEntities/' + this.legalEntityId + '/subsidyCreditors')
      if (await kreditors.data.length) {
        kreditors.data.forEach((kred) => {
          kred.oldIban = kred.iban
        })
        let filteredCreditors = []
        //filter creditors
        if (this.creditorType == 'processing') {
          filteredCreditors = kreditors.data.filter((cred) => cred.localUnitId !== null)
        } else {
          //creditorType == 'production'
          filteredCreditors = kreditors.data.filter((cred) => cred.localUnitId === null)
        }

        this.kreditors = filteredCreditors
        this.kreditor = this.kreditors[0]
      }
      return true
      //try {
      //} catch (e) {
      //  emitter.emit('toastError','Kreditor konnte nicht geladen werden ..' + JSON.stringify(e.message))
      //  return true
      //}
    },
    loadAddress: async function () {
      try {
        const result = await app.axios.get(apiURL + '/legalEntities/' + this.legalEntityId + '/defaultAddress')
        this.defaultAddress = await result.data
        return true
      } catch (e) {
        emitter.emit('toastError', 'Adresse konnte nicht geladen werden ..' + JSON.stringify(e.message))
      }
    },
    async loadLocations(zip) {
      if (zip > 1000) {
        const localityByZip = await app.axios.get(apiURL + '/postcodes/findByZip', { params: { zip: zip } }) //immer alle
        this.locations = localityByZip.data.content.map((item) => item.localityLong)
        if (this.locations.length > 0) {
          this.tmpZulagen.locality = this.locations[0]
          this.tmpZulagen.countryRegion = 'CH_' + localityByZip.data.content[0].canton.toUpperCase() // CH_BE
        }
      } else {
        this.locations = []
        this.tmpZulagen.locality = ''
        this.tmpZulagen.countryRegion = null
      }
      /*
      if (this.zipRule) {
        try {
          const locationsFromPostAPI = await this.openDataService.loadLocations(zip)
          this.locations = locationsFromPostAPI.map((i) => i.record).map((i) => i.fields)
          this.tmpZulagen.locality = this.locations[0].ortbez27 // set first entry as default
          this.tmpZulagen.countryRegion = 'CH_' + this.locations[0].kanton // set as default
        } catch (e) {
          this.tmpZulagen.locality = null
          this.tmpZulagen.countryRegion = null
          emitter.emit('toastError','Ortschaft konnte nicht geladen werden ...' + JSON.stringify(e.message))
        }
      }
      */
    }
  },
  watch: {
    'tmpZulagen.ibanEingabe': function (newVal) {
      // hübsch formatieren
      this.tmpZulagen.iban = newVal.replace(/\s/gi, '')
      // Bank  Laden
      if (this.tmpZulagen.iban.length >= 9) {
        if (this.bcn != this.tmpZulagen.iban.substring(4, 9)) {
          this.bcn = this.tmpZulagen.iban.substring(4, 9)
          this.setBankByIBAN(this.tmpZulagen.iban)
        }
      }

      // Fehlermeldungen generieren
      if (!(this.tmpZulagen.iban.substring(0, 2) === 'CH' || this.tmpZulagen.iban.substring(0, 2) === 'LI')) {
        this.tmpZulagen.ibanError = this.$t('subsidycreditor_ibannurlaender')
      } else if (this.tmpZulagen.iban.length < 21) {
        // CH == 21 https://de.wikipedia.org/wiki/Internationale_Bankkontonummer
        this.tmpZulagen.ibanError = this.$t('subsidycreditor_ibanzukurz')
      } else if (this.tmpZulagen.iban.length > 21) {
        this.tmpZulagen.ibanError = this.$t('subsidycreditor_ibanzulang')
      } else if (!IBAN.isValid(this.tmpZulagen.iban)) {
        this.tmpZulagen.ibanError = this.$t('subsidycreditor_ibanungueltig')
      } else {
        this.tmpZulagen.ibanError = ''
      }
    }
  },
  async mounted() {
    this.loadAddress()
    useCountryRegionsStore().fetchCountryRegions()
    await this.loadDisableSubsidyCreditorFromSubsidyConfig()
    await this.loadSubsidyCreditor()
    if (this.$route.path.includes('processing')) {
      this.loadDairies()
    } else {
      if (!this.isSAPKreditor && !this.disableSubsidyCreditor) this.dialog = true
    }
  }
})
</script>
