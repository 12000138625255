import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'

export const useCountryRegionsStore = defineStore('CountryRegionsStore', {
  state: () => ({
    CountryRegions: [] as any[]
  }),
  actions: {
    async fetchCountryRegions() {
      if (this.CountryRegions.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/countryRegions')
          this.CountryRegions = result.data.filter((reg: { name: string }) => reg.name != 'CH_NONE')
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
